import React, { useState, useRef } from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import { mergeWith } from 'lodash';
import { useDispatch } from 'react-redux';
import { Menu, Card, Icon, Button, Tooltip } from 'mw-style-react';

import { useIntl } from 'hooks';
import ResizableCells from '@control-front-end/common/components/ResizableCells';
import { EXP_NODE } from '@control-front-end/common/constants/graphActors';
import { GRAPH_DISCOVERY } from '@control-front-end/common/constants/graphLayers';
import Dashboard from '@control-front-end/common/components/Dashboard';
import useGridExpNodesSnap from '@control-front-end/app/src/components/GraphEngine/useGridExpNodesSnap';
import ContextMenuItem from '../../../../routes/ActorsGraph/components/ContextMenuItem';
import mes from './intl';
import scss from './ExpandedChart.scss';

/**
 * Component to render dashboard actor on layer
 * @param el
 * @param graph
 * @param handleRemoveNode
 * @param handleRenameNode
 * @param handleMakeActiveElement
 * @param handleSaveActorLayerSettings
 * @returns {JSX.Element}
 * @constructor
 */
function ExpandedChart({
  el,
  graph,
  handleRemoveNode,
  handleRenameNode,
  handleMakeActiveElement,
  handleSaveActorLayerSettings,
}) {
  const t = useIntl();
  const rootRef = useRef();
  const dragRef = useRef();
  const [transformPosition, setTransformPosition] = useState(el.position());
  const nodeId = el.id();
  const { actorId, laId, layerSettings = {}, privs, readOnly } = el.data();
  const canEdit = !readOnly && privs && privs.modify;
  const [menu, toggleMenu] = useState(false);

  const dispatch = useDispatch();

  const closeMenu = () => toggleMenu(false);

  /**
   * Open actors panel
   */
  const handleOpenPanel = () => {
    handleMakeActiveElement({
      e: { target: el },
      graph,
      extra: { openPanel: true },
    });
    closeMenu();
  };

  /**
   * Chart node position change
   */
  const handleMoveNavigator = () => {
    setTransformPosition({ ...el.position() });
  };

  /**
   * Pin/unpin chart node
   */
  const handlePinNode = () => {
    handleSaveActorLayerSettings({
      id: nodeId,
      settings: {
        pin: !layerSettings.pin,
      },
    });
    closeMenu();
  };

  /**
   * Save chart size after resize stop
   */
  const handleResizeStop = (e, { size: newSize, offset }) => {
    handleSaveActorLayerSettings(
      {
        id: nodeId,
        settings: {
          height: Math.round(newSize.height),
          width: Math.round(newSize.width),
          offset,
        },
      },
      () =>
        dispatch({
          type: GRAPH_DISCOVERY.EXPAND_NODE.REQUEST,
          expand: layerSettings.expand,
          position: el.position(),
          offset: mergeWith(
            { ...offset },
            layerSettings.offset || EXP_NODE.offset.none,
            (a, b) => a - b
          ),
        })
    );
  };

  const handleOnMoveUp = () => {
    setTransformPosition({ ...el.position() });
  };

  const handleOnMoveDown = () => {
    const expN = document.querySelectorAll('.chart');
    expN.forEach((node) => node.style.removeProperty('z-index'));
    if (rootRef.current) rootRef.current.style['z-index'] = 1;
  };

  useGridExpNodesSnap({
    cy: graph,
    node: el,
    ref: dragRef,
    isDraggable: !layerSettings.pin,
    onDrag: handleMoveNavigator,
    onUp: handleOnMoveUp,
    onDown: handleOnMoveDown,
  });

  /**
   * Render menu of layer actor
   */
  const renderMenu = () => {
    const canRemove = el.data('privs').remove;
    return (
      <div styleName="chart__header__menu" onClick={(e) => e.stopPropagation()}>
        <Menu
          styleName="chart__header__menu wrap"
          size="small"
          onClick={() => closeMenu()}
          onClose={() => setTimeout(closeMenu, 10)}
        >
          <ContextMenuItem
            icon="panel_view"
            label={t(mes.openActorPanel)}
            handleClick={handleOpenPanel}
          />
          <ContextMenuItem
            icon="trash"
            label={t(mes.remove)}
            visibility={!canRemove ? 'disabled' : 'visible'}
            handleClick={() => handleRemoveNode(el.id(), readOnly)}
          />
        </Menu>
      </div>
    );
  };

  const expandSizeType = EXP_NODE.sizeType.default;

  return (
    <ResizableCells
      initialSize={{
        width: layerSettings.width || EXP_NODE.size[expandSizeType].width,
        height: layerSettings.height || EXP_NODE.size[expandSizeType].height,
      }}
      resizeHandles={['s', 'w', 'e', 'n', 'sw', 'nw', 'se', 'ne']}
      onResizeStop={handleResizeStop}
      minConstraints={[
        EXP_NODE.size[expandSizeType].width,
        EXP_NODE.size[expandSizeType].height,
      ]}
      position={{ ...transformPosition }}
      offset={layerSettings.offset || EXP_NODE.offset[expandSizeType]}
    >
      <Card forwardRef={rootRef} styleName="chart" className="chart">
        <Dashboard
          actorId={actorId}
          laId={laId}
          headerRef={dragRef}
          draggable={!layerSettings.pin}
          canEdit={canEdit}
          showEditButton
          onTitleChange={(value) =>
            handleRenameNode({ id: nodeId, title: value })
          }
          actions={
            <>
              <Tooltip value={t(mes.more)}>
                <div
                  onClick={(e) => {
                    e.stopPropagation();
                    toggleMenu(!menu);
                  }}
                >
                  <Icon type="more" />
                  {menu ? renderMenu() : null}
                </div>
              </Tooltip>
              <Tooltip
                key={`${nodeId}_${layerSettings.pin ? 'unpin' : 'pin'}`}
                value={layerSettings.pin ? t(mes.unpin) : t(mes.pin)}
              >
                <Button
                  size="small"
                  icon="pin"
                  rounded
                  className={cn(scss.pinButton, {
                    [scss.pinned]: layerSettings.pin,
                  })}
                  onClick={(e) => {
                    e.stopPropagation();
                    e.preventDefault();
                    handlePinNode();
                  }}
                />
              </Tooltip>
              <Tooltip value={t(mes.collapse)}>
                <div
                  onClick={(e) => {
                    e.stopPropagation();
                    handleSaveActorLayerSettings(
                      {
                        id: nodeId,
                        settings: { expand: false },
                      },
                      () =>
                        dispatch({
                          type: GRAPH_DISCOVERY.EXPAND_NODE.REQUEST,
                          expand: false,
                          position: el.position(),
                          offset: layerSettings.offset,
                        })
                    );
                  }}
                >
                  <Icon size="small" type="close" />
                </div>
              </Tooltip>
            </>
          }
        />
      </Card>
    </ResizableCells>
  );
}

ExpandedChart.propTypes = {
  el: PropTypes.object.isRequired,
  graph: PropTypes.object,
  handleRemoveNode: PropTypes.func.isRequired,
  handleRenameNode: PropTypes.func.isRequired,
  handleMakeActiveElement: PropTypes.func.isRequired,
  handleSaveActorLayerSettings: PropTypes.func.isRequired,
};

export default ExpandedChart;
