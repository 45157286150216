import React, { useState, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import { Icon, Utils } from 'mw-style-react';
import AppUtils from '@control-front-end/utils/utils';
import { STORAGE_KEY } from '@control-front-end/common/constants';
import { MIN_PANEL_WIDTH } from '@control-front-end/common/constants/graphActors';
import './ResizablePanel.scss';
import { useSelector } from 'react-redux';
const EXPANDED_MENU_WIDTH = 240;

function ResizablePanel({
  onChange,
  children,
  storageKeyName,
  movePanelOnMenuCollapse,
}) {
  const resizer = useRef();
  const [isResizing, setIsResizing] = useState(false);
  const isFirstRender = useRef(true);
  const panelRef = useRef();
  const { collapsedSidebar } = useSelector((state) => state.settings);
  const { defaultWidth: defaultPanelWidth, maxWidth: maxPanelWidth } =
    AppUtils.getDefaultPanelParams(storageKeyName);
  const [width, setWidth] = useState(() =>
    Number(Utils.fromStorage(storageKeyName) || defaultPanelWidth)
  );

  /**
   * Handler for changing the size of the panel
   */
  const handleResizePanel = (e) => {
    const rect = panelRef.current.getBoundingClientRect();
    let newWidth = rect.width + rect.left - e.clientX;
    if (newWidth < MIN_PANEL_WIDTH) {
      newWidth = MIN_PANEL_WIDTH;
    }
    if (newWidth > maxPanelWidth) {
      newWidth = maxPanelWidth;
    }
    setWidth(newWidth);
    Utils.toStorage(storageKeyName, newWidth);
    onChange?.(newWidth);
  };

  useEffect(() => {
    if (!movePanelOnMenuCollapse) return;
    // Skip first render, change panel width only when collapsedSidebar changes
    if (isFirstRender.current) {
      isFirstRender.current = false;
      return;
    }
    const newPanelWidth = collapsedSidebar
      ? Math.min(width + EXPANDED_MENU_WIDTH, maxPanelWidth)
      : Math.max(width - EXPANDED_MENU_WIDTH, MIN_PANEL_WIDTH);
    setWidth(newPanelWidth);
    onChange?.(newPanelWidth);
    Utils.toStorage(STORAGE_KEY.panelWidth.event, newPanelWidth);
  }, [collapsedSidebar]);

  const stopResize = () => {
    setIsResizing(false);
    document.removeEventListener('mousemove', handleResizePanel, false);
    document.removeEventListener('mouseup', stopResize, false);
  };

  useEffect(() => {
    const initResize = () => {
      setIsResizing(true);
      document.addEventListener('mousemove', handleResizePanel, false);
      document.addEventListener('mouseup', stopResize, false);
    };

    const resizerElement = resizer.current;

    resizerElement.addEventListener('mousedown', initResize, false);
    return () => {
      resizerElement?.removeEventListener('mousedown', initResize, false);
    };
  }, []);

  return (
    <div ref={panelRef} style={{ width: `${width}px` }} styleName="wrap">
      <div styleName={cn('panel', { resizing: isResizing })}>
        <div ref={resizer} styleName="panel__resizer">
          <Icon size="small" type="more" />
        </div>
        <div styleName="panel__content">{children}</div>
      </div>
    </div>
  );
}

ResizablePanel.defaultProps = {
  storageKeyName: STORAGE_KEY.panelWidth.default,
  movePanelOnMenuCollapse: false,
};

ResizablePanel.propTypes = {
  onChange: PropTypes.func,
  children: PropTypes.node,
  storageKeyName: PropTypes.string,
  movePanelOnMenuCollapse: PropTypes.bool,
};

export default ResizablePanel;
