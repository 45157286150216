import React, { useState, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import cn from 'classnames';
import { mergeWith } from 'lodash';
import { ErrorBoundary } from 'react-error-boundary';
import {
  Icon,
  Label,
  Menu,
  Tooltip,
  ProgressBar,
  Utils,
  Divider,
  ToggleSwitch,
  cr,
} from 'mw-style-react';
import CDU from 'control-cdu';

import {
  EXPANDED_LAYER_LOADED,
  TOGGLE_VERTICAL_LINKS,
  GRAPH_DISCOVERY,
} from '@control-front-end/common/constants/graphLayers';
import { APP_AUTH_KEY, PM_APP_NAME, SET_MODAL, SHOW_NOTIFY } from 'constants';
import { useOutsideClick, useIntl } from 'hooks';

import AppUtils from '@control-front-end/utils/utils';
import { EXP_NODE } from '@control-front-end/common/constants/graphActors';
import ResizableCells from '@control-front-end/common/components/ResizableCells';
import ActorAvatar from '@control-front-end/common/components/ActorAvatar';
import useGridExpNodesSnap from '@control-front-end/app/src/components/GraphEngine/useGridExpNodesSnap';
import { snap } from '@control-front-end/utils/modules/utilsCellCoords';

import ContextMenuItem from '../../../../routes/ActorsGraph/components/ContextMenuItem';
import ExpandedNodeTitle from './ExpandedNodeTitle';
import './ExpNode.scss';
import mes from './intl';

const MemoCDU = React.memo(CDU, () => {
  return true;
});

/**
 * Actor expanded on layer
 */
function ExpNode(props) {
  const {
    activeWorkspace,
    el,
    graph,
    graphFolderId,
    layerId,
    isSingleLayerModel,
    isLayerReadOnly,
    handleSaveActorLayerSettings,
    handleMakeActiveElement,
    handleLoadLayer,
    handleShowLinkedLayers,
    handleCopyActorLink,
    handleRemoveNode,
    handleRenameNode,
    panCenterNodeViewBox,
    handleClickOutside,
  } = props;
  const t = useIntl();
  const dispatch = useDispatch();
  const rootRef = useRef();
  const dragRef = useRef();
  const iframeRef = useRef();
  const systemForms = useSelector((state) => state.systemForms) || {};
  const [menu, toggleMenu] = useState(false);
  const [loading, setLoader] = useState(true);
  const [position, setPosition] = useState(() => ({ ...el.position() }));
  const [resizing, setResizing] = useState(false);
  const [vLinks, setVlinks] = useState(true);
  const [selected, setSelected] = useState(el.selected());
  const initTitle = el.data('title');
  const [title, setTitle] = useState(initTitle);
  const [isTitleEdit, toggleTitleEdit] = useState(false);
  const color = el.data('color') || '#B0B6BF';
  const layerSettings = el.data('layerSettings') || {};
  const [fullScreenSize, setFullScreenSize] = useState(null);

  const expandSizeType =
    systemForms.events?.id === el.data('formId')
      ? EXP_NODE.sizeType.w9h13
      : EXP_NODE.sizeType.default;

  const isActorProfile =
    layerSettings.expandType === EXP_NODE.contentType.profile ||
    layerSettings.profile;
  const nodeId = el.id();
  const actorId = el.data('actorId');
  const isSystem = el.data('isSystem');
  const formId = el.data('formId');
  const nodeContentType = cr(
    [
      isActorProfile || systemForms.events?.id === el.data('formId'),
      EXP_NODE.contentType.actor,
    ],
    [systemForms.scripts?.id === formId, EXP_NODE.contentType.script],
    [true, EXP_NODE.contentType.layer]
  );

  const flaskView = cr(
    // For layer content type by default  flaskView: true (cause graph horizontal bar is overlapped by exp-node toolbar)
    [
      nodeContentType === EXP_NODE.contentType.layer,
      layerSettings.flaskView !== false,
    ],
    // For other content types by default {flaskView: false}
    [true, Boolean(layerSettings.flaskView)]
  );

  /**
   * Show/hide vertical links
   */
  const handleToggleVlinks = () => {
    const showLinks = !vLinks;
    setVlinks(showLinks);
    const iframeId = `layer_${nodeId}`;
    const iframe = document.getElementById(iframeId);
    if (!iframe) return;
    iframe.contentWindow.postMessage(
      {
        appName: PM_APP_NAME,
        type: TOGGLE_VERTICAL_LINKS,
        payload: { showLinks },
      },
      window.parent.origin
    );
  };

  /**
   * Maximize expanded node window
   */
  const setFullScreen = () => {
    if (!rootRef.current) return;
    const { h, w } = graph.extent();
    setFullScreenSize(
      layerSettings.fullScreen ? snap({ width: w - 50, height: h - 150 }) : null
    );
    if (iframeRef.current) {
      iframeRef.current.contentWindow.postMessage(
        {
          appName: PM_APP_NAME,
          type: 'MIN_MAX_IFRAME',
          payload: {},
        },
        window.parent.origin
      );
    }
  };

  /**
   * Save edited title
   */
  const handleSaveTitle = () => {
    const newTitle = iframeRef.current.title.trim();
    if (newTitle.length === 0) {
      setTitle(initTitle);
    } else if (newTitle !== initTitle) {
      handleRenameNode({ id: nodeId, title: newTitle });
    }
    toggleTitleEdit(false);
  };

  /**
   * iFrame clicks handler
   */
  useEffect(() => {
    const handler = ({ data }) => {
      switch (data.type) {
        case 'OPEN_ACTOR_VIEW_MODAL':
          if (data.data?.layerId && data.data.layerId !== actorId) return;
          dispatch({
            type: SET_MODAL,
            payload: { name: 'ActorModalView', data: data.data },
          });
          break;
        case 'OPEN_ACTOR_EDIT_MODAL':
          const { actor } = data.data;
          if (actor.actorId !== actorId) return;
          dispatch({
            type: SET_MODAL,
            payload: {
              name: 'CreateActor',
              data: {
                ...actor,
                form: {
                  ...actor.form,
                  id: actor.formId,
                  title: actor.formTitle,
                },
                forms: actor.forms,
              },
              closeConfirm: true,
            },
          });
          break;
        case 'GRAPH_CONTENT_CLICK':
          toggleTitleEdit(false);
          handleClickOutside();
          if (data.layerId) setSelected(data.layerId === actorId);
          break;
        case 'SHOW_NOTIFY_TOP_WINDOW':
          dispatch({
            type: SHOW_NOTIFY.REQUEST,
            payload: data.data?.payload,
          });
          break;
        case 'UPDATE_NODE_TITLE':
          const { id, title: newTitle } = data.data;
          if (id !== actorId || !newTitle) return;
          setTitle(newTitle);
          break;
        default:
          break;
      }
    };
    window.addEventListener('message', handler);
    return () => {
      window.removeEventListener('message', handler);
    };
  }, []);

  useEffect(() => {
    setFullScreen();
  }, [layerSettings.fullScreen]);

  useOutsideClick({
    ref: rootRef,
    callback: () => {
      setSelected(false);
    },
  });

  useEffect(() => {
    const iframeEl = iframeRef.current;
    if (!iframeEl) return;
    iframeEl.style.pointerEvents = resizing ? 'none' : 'all';
  }, [resizing]);

  /**
   * Click on multilayer header
   */
  const handleHeaderClick = () => {
    handleClickOutside();
    setSelected(true);
  };

  /**
   * Actor's position movement
   */
  const handleMoveNavigator = () => {
    setPosition({ ...el.position() });
  };

  /**
   * Remove blocking div above iframe while drag&drop
   */
  const handleOnMoveUp = () => {
    setPosition({ ...el.position() });
  };

  /**
   * Pin/unpin node
   */
  const handlePinNode = () => {
    handleSaveActorLayerSettings({
      id: nodeId,
      settings: {
        pin: !layerSettings.pin,
      },
    });
  };

  /**
   * Turn on/off iframe fullscreen mode
   */
  const handleFullScreen = () => {
    handleSaveActorLayerSettings({
      id: nodeId,
      settings: {
        fullScreen: !layerSettings.fullScreen,
      },
    });
  };

  /**
   * Set blocking div above iframe while drag&drop
   */
  const handleOnMoveDown = () => {
    const expN = document.querySelectorAll('.nl');
    expN.forEach((node) => node.style.removeProperty('z-index'));
    rootRef.current.style['z-index'] = 1;
  };

  /**
   * Open in new tab
   */
  const handleOpenInNewTab = () => {
    const accId = el.data('accId');
    let url;
    if (nodeContentType === EXP_NODE.contentType.layer) {
      const isLayerActor = systemForms.layers?.id === formId;
      const graphMode = isLayerActor ? 'layers' : 'actors';
      const graphId = isLayerActor ? graphFolderId : '0';
      url = AppUtils.makeUrl(
        `/actors_graph/${accId}/graph/${graphId}/${graphMode}/${actorId}`
      );
    } else if (nodeContentType === EXP_NODE.contentType.actor) {
      url = AppUtils.makeUrl(`/actors_graph/${accId}/view/${actorId}`);
    } else {
      url = AppUtils.makeUrl(
        `/script/${accId}/view/${el.data('ref')}/production/index`
      );
    }
    AppUtils.openTabWithNewId(url);
    toggleMenu(false);
  };

  /**
   * Save window size after resize stop
   */
  const handleResizeStop = (e, { size: newSize, handle, offset }) => {
    const { width, height } = layerSettings;
    if (iframeRef.current) {
      const panX = handle.includes('w') ? newSize.width - width : 0;
      const panY = handle.includes('n') ? newSize.height - height : 0;
      if (panX || panY) {
        iframeRef.current.contentWindow.postMessage(
          {
            appName: PM_APP_NAME,
            type: 'PAN_GRAPH',
            payload: { x: panX, y: panY },
          },
          window.parent.origin
        );
      }
    }
    handleSaveActorLayerSettings(
      {
        id: nodeId,
        settings: {
          fullScreen: false,
          height: Math.round(newSize.height),
          width: Math.round(newSize.width),
          offset,
        },
      },
      () =>
        dispatch({
          type: GRAPH_DISCOVERY.EXPAND_NODE.REQUEST,
          expand: layerSettings.expand,
          position,
          offset: mergeWith(
            { ...offset },
            layerSettings.offset || EXP_NODE.offset.none,
            (a, b) => a - b
          ),
        })
    );
    setResizing(false);
  };

  /**
   * Turn Flask view on/off
   */
  const handleToggleFlaskView = () => {
    handleSaveActorLayerSettings({
      id: nodeId,
      settings: { flaskView: !flaskView },
    });
  };

  /**
   * Open actor's or actors group panel
   */
  const handleOpenPanel = () => {
    handleMakeActiveElement({
      e: { target: el },
      graph,
      extra: { openPanel: true },
    });
  };

  /**
   * Render menu of layer actor
   */
  const renderMenu = () => {
    if (!menu || loading) return null;
    const isLayerActor =
      nodeContentType === EXP_NODE.contentType.layer &&
      systemForms.layers?.id === formId;
    const canRemove = el.data('privs').remove;
    return (
      <div styleName="nl__header__menu" onClick={(e) => e.stopPropagation()}>
        <Menu
          styleName="nl__header__menu wrap"
          size="small"
          width={250}
          onClick={() => toggleMenu(false)}
          onClose={() => setTimeout(() => toggleMenu(false), 10)}
        >
          <ContextMenuItem
            icon="link_external"
            label={t(mes.openNewTab)}
            visibility="visible"
            handleClick={() => handleOpenInNewTab()}
          />
          <ContextMenuItem
            icon="flask"
            label={t(mes.flaskView)}
            handleClick={() => {
              handleToggleFlaskView();
              toggleMenu(false);
            }}
            visibility={
              nodeContentType === EXP_NODE.contentType.actor
                ? 'hidden'
                : 'visible'
            }
          >
            <ToggleSwitch value={flaskView} onChange={handleToggleFlaskView} />
          </ContextMenuItem>
          <ContextMenuItem
            icon="connection"
            label={t(mes.externalLinks)}
            handleClick={() => {
              handleToggleVlinks();
              toggleMenu(false);
            }}
            visibility={isLayerActor ? 'visible' : 'hidden'}
          >
            <ToggleSwitch value={vLinks} onChange={handleToggleVlinks} />
          </ContextMenuItem>
          <Divider styleName="nl__header__menu__divider" />
          <ContextMenuItem
            icon="panel_view"
            label={
              isSingleLayerModel
                ? t(mes.openActorDetails)
                : t(mes.openActorPanel)
            }
            visibility="visible"
            handleClick={() => {
              if (window.frameElement) {
                window.top.postMessage(
                  {
                    appName: PM_APP_NAME,
                    type: 'OPEN_ACTOR_VIEW_MODAL',
                    data: { actorId },
                  },
                  window.parent.origin
                );
              } else handleOpenPanel();
              toggleMenu(false);
            }}
          />
          <ContextMenuItem
            icon="actor"
            label={t(mes.showActorsLayer)}
            visibility={isSingleLayerModel ? 'disabled' : 'visible'}
            handleClick={() => {
              handleLoadLayer({ id: actorId, graphMode: 'actors' });
            }}
          />
          <Divider styleName="nl__header__menu__divider" />
          <ContextMenuItem
            icon="linked_layers"
            label={t(mes.linkedLayers)}
            visibility={
              isSingleLayerModel ||
              nodeContentType === EXP_NODE.contentType.actor
                ? 'hidden'
                : 'visible'
            }
            handleClick={() => {
              handleShowLinkedLayers(el);
              toggleMenu(false);
            }}
          />
          <ContextMenuItem
            icon="link"
            label={t(mes.copyActorLink)}
            handleClick={() => {
              handleCopyActorLink(actorId);
              toggleMenu(false);
            }}
          />
          <Divider styleName="nl__header__menu__divider" />
          <ContextMenuItem
            icon="trash"
            label={t(mes.remove)}
            visibility={
              isLayerReadOnly || isSystem || !canRemove ? 'disabled' : 'visible'
            }
            handleClick={() => handleRemoveNode(el.id(), el.data('readOnly'))}
          />
        </Menu>
      </div>
    );
  };

  /**
   * Load actor layer
   */
  const renderLayerActor = () => {
    const iframeId = `layer_${nodeId}`;
    const isLayerActor = systemForms.layers?.id === el.data('formId');
    const graphMode = isLayerActor ? 'layers' : 'actors';
    return (
      <>
        {loading ? (
          <div styleName="nl__loader">
            <ProgressBar type="circle" size="large" />
          </div>
        ) : null}
        <iframe
          style={loading ? { display: 'none' } : {}}
          ref={iframeRef}
          styleName="nl__iframe"
          id={iframeId}
          title={title}
          src={`/layer/${el.data('accId')}/single/${graphMode}/${el.data(
            'actorId'
          )}?appName=${PM_APP_NAME}&graphFolderId=${graphFolderId}&enableBgGrid=true&enableQuickActions=true`}
          onLoad={() => {
            setLoader(false);
            dispatch({
              type: EXPANDED_LAYER_LOADED,
              payload: { iframeId, actorId },
            });
          }}
        />
      </>
    );
  };

  /**
   * Load script
   */
  const renderScriptActor = () => {
    const errorFallback = ({ error }) => (
      <div styleName="nl__error">
        <Icon type="alert" error />
        <Label value={error.message} />
      </div>
    );

    return (
      <ErrorBoundary FallbackComponent={errorFallback}>
        <div styleName="nl__content__script">
          <MemoCDU
            key={nodeId}
            auth={Utils.fromStorage(APP_AUTH_KEY)}
            workspace={activeWorkspace}
            app={el.data('ref')}
            context={{
              graphFolderId,
              appId: nodeId,
              actorId: layerId,
            }}
            stage="production"
            page="index"
          />
        </div>
      </ErrorBoundary>
    );
  };

  /**
   * Load actor card
   */
  const renderActorCard = () => {
    const iframeId = `layer_${actorId}`;
    const accId = el.data('accId');
    return (
      <>
        {loading ? (
          <div styleName="nl__loader">
            <ProgressBar type="circle" size="large" />
          </div>
        ) : null}
        <iframe
          id={`actorIframe_${actorId}`}
          ref={iframeRef}
          styleName="nl__iframe"
          title={title}
          src={`/actor/${accId}/view/${el.data(
            'actorId'
          )}?iFrame=true&compact=true`}
          onLoad={() => {
            setLoader(false);
            dispatch({
              type: EXPANDED_LAYER_LOADED,
              payload: { iframeId, actorId },
            });
          }}
        />
      </>
    );
  };

  const renderContent = () => {
    switch (nodeContentType) {
      case EXP_NODE.contentType.actor:
        return renderActorCard();
      case EXP_NODE.contentType.layer:
        return renderLayerActor();
      case EXP_NODE.contentType.script:
        return renderScriptActor();
      default:
        return null;
    }
  };

  const { isDragging } = useGridExpNodesSnap({
    cy: graph,
    node: el,
    ref: dragRef,
    isDraggable: !layerSettings.pin,
    onDrag: handleMoveNavigator,
    onUp: handleOnMoveUp,
    onDown: handleOnMoveDown,
  });

  const { fullScreen, offset, pin } = layerSettings;

  return (
    <ResizableCells
      initialSize={{
        width: layerSettings.width || EXP_NODE.size[expandSizeType].width,
        height: layerSettings.height || EXP_NODE.size[expandSizeType].height,
      }}
      size={fullScreenSize}
      resizeHandles={['s', 'w', 'e', 'n', 'sw', 'nw', 'se', 'ne']}
      onResizeStart={() => setResizing(true)}
      onResizeStop={handleResizeStop}
      minConstraints={[
        EXP_NODE.size[expandSizeType].width,
        EXP_NODE.size[expandSizeType].height,
      ]}
      position={{ ...position }}
      offset={offset || EXP_NODE.offset[expandSizeType]}
    >
      <div
        ref={rootRef}
        className="nl"
        styleName={cn('nl', nodeContentType, {
          flaskView,
          selected,
        })}
        style={{
          borderColor: selected && el.data('color') ? el.data('color') : null,
          backgroundColor: flaskView
            ? AppUtils.hexToRgba(color, 0.35)
            : 'rgba(235, 238, 242, 0.85)',
        }}
        onClick={handleHeaderClick}
      >
        <div
          ref={dragRef}
          className="drag"
          styleName={cn('nl__header', { pinned: pin })}
        >
          <div
            style={{
              backgroundColor: flaskView
                ? AppUtils.hexToRgba(color, 0.55)
                : null,
            }}
          >
            <Icon size="large" type="drag" />
            <ActorAvatar
              size="micro"
              type="compact"
              formType={el.data('formType')}
              formTitle={el.data('formTitle')}
              pictureUrl={el.data('pictureUrl')}
              colors={el.data('allColors')}
              colorFilled={true}
            />
            <ExpandedNodeTitle
              title={title}
              initTitle={initTitle}
              isTitleEdit={isTitleEdit}
              readOnly={el.data('readOnly')}
              onToggleEdit={toggleTitleEdit}
              onChange={(value) => setTitle(value)}
              onSave={handleSaveTitle}
            />
            <div styleName="nl__header__actions">
              <div
                styleName="nl__header__more"
                onClick={() => {
                  handleHeaderClick();
                  toggleMenu(!menu);
                }}
              >
                <Icon type="more" />
                {menu ? renderMenu() : null}
              </div>
              <Tooltip
                key={`${nodeId}_${pin ? 'unpin' : 'pin'}`}
                value={pin ? t(mes.unpin) : t(mes.pin)}
              >
                <div
                  styleName={cn('nl__header__pin', {
                    pinned: pin,
                  })}
                  onClick={() => {
                    handleHeaderClick();
                    handlePinNode();
                  }}
                >
                  <Icon size="small" type="pin" />
                </div>
              </Tooltip>
              <Tooltip
                key={`${nodeId}_${fullScreen ? 'minimize' : 'maximize'}`}
                value={fullScreen ? t(mes.minimize) : t(mes.maximize)}
              >
                <div
                  styleName="nl__header__fullscreen"
                  onClick={() => {
                    handleHeaderClick();
                    handleFullScreen();
                    setTimeout(() => {
                      panCenterNodeViewBox({ e: { target: el }, graph });
                    }, 200);
                  }}
                >
                  <Icon
                    size="small"
                    type={fullScreen ? 'page_small' : 'page'}
                  />
                </div>
              </Tooltip>
              <Tooltip value={t(mes.collapse)}>
                <div
                  styleName="nl__header__collapse"
                  onClick={() => {
                    handleSaveActorLayerSettings(
                      {
                        id: nodeId,
                        settings: isActorProfile
                          ? { profile: false, expand: false }
                          : { expand: false },
                      },
                      () =>
                        dispatch({
                          type: GRAPH_DISCOVERY.EXPAND_NODE.REQUEST,
                          expand: false,
                          position,
                          offset: layerSettings.offset,
                        })
                    );
                  }}
                >
                  <Icon size="small" type="close" />
                </div>
              </Tooltip>
            </div>
          </div>
        </div>
        <div styleName="nl__content">
          {isDragging ? <div styleName="nl__dis" /> : null}
          {renderContent()}
        </div>
      </div>
    </ResizableCells>
  );
}

ExpNode.propTypes = {
  el: PropTypes.object.isRequired,
  activeWorkspace: PropTypes.string,
  graph: PropTypes.object,
  graphFolderId: PropTypes.string,
  layerId: PropTypes.string,
  isSingleLayerModel: PropTypes.bool,
  isLayerReadOnly: PropTypes.bool,
  handleSaveActorLayerSettings: PropTypes.func.isRequired,
  panCenterNodeViewBox: PropTypes.func.isRequired,
  handleMakeActiveElement: PropTypes.func.isRequired,
  handleLoadLayer: PropTypes.func.isRequired,
  handleShowLinkedLayers: PropTypes.func.isRequired,
  handleCopyActorLink: PropTypes.func.isRequired,
  handleRemoveNode: PropTypes.func.isRequired,
  handleRenameNode: PropTypes.func.isRequired,
  handleClickOutside: PropTypes.func.isRequired,
};

export default ExpNode;
